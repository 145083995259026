import React, { useRef } from "react"
import emailjs from "@emailjs/browser"
import "../styles/Contact.scss"

function Contact() {
  
  const form = useRef()

  const sendEmail = (e) => {
    emailjs.sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", form.current, "YOUR_PUBLIC_KEY").then(
      (result) => {
        console.log(result.text)
      },
      (error) => {
        console.log(error.text)
      }
    )
  }

  return (
    <div className="contact">
      <div className="gray_background">
        <div className="wrapper">
          <h2>Contact Us</h2>

          <div className="background">
            <div className="_column">
              <form className="center_column" ref={form} onSubmit={sendEmail}>
                <input className="input" placeholder="Name" type="text" name="user_name"></input>
                <input className="input" placeholder="Email address" type="email" name="user_email"></input>
                <textarea className="message" placeholder="Your message" name="message"></textarea>
                <button type="submit" value="Send">
                  Send
                </button>
              </form>
            </div>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing</p>
              <p>+1-647-283-05-04</p>
              <p>contactus@tap2give.info</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
