import React from "react"
import { Link } from "react-router-dom"
import "../styles/Home.scss"
import { Nayax, Onyx, Dash } from "../assets/images"

function Home() {
  return (
    <div className="home">
      <div className="hero">
        <div className="wrapper_home">
          <div className="header">
            <h1>Make the Change</h1>
            <p>Company that specialized in custom made donation solutions for charity organizations.</p>
            <Link to="/Solutions">
              <button>Learn more</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="block">
        <div>
          <img className="image_big" src={Nayax} alt="Nayax devices" />
        </div>
        <div className="block_text">
          <h1>Why Tap2Give</h1>
          <img src={Dash} alt="" />
          <p>Did you know the world outside is going more and more cashless?</p>
          <p>Did you know that statistics today show that COVID is ensuring the rapid decline of cash usage all over the world?</p>
          <p> Cashless payments are easy and quick, accessible in many formats, instantly reconcilable, and allows much data to be captured and reported.</p>
          <Link to="/Contact">
            <button>Contact us</button>
          </Link>
        </div>
      </div>
      <div className="gray_background">
        <div className="block">
          <div className="block_text">
            <h1>What We Do</h1>
            <img src={Dash} alt="Dash" />
            <p>
              Tap2Give powered by Nayax brings that ease and convenience to the world of charity and most especially for synagogues and other Jewish nonprofits. Why? It is a cost - effective solution offering a great opportunity for the
              nonprofit.
            </p>
            <p>
              Charitable organizations do not have to purchase it themselves but instead have enable a synagogue member or a donor to donate DIGITAL TZEDAKAH - powered by Nayax - to the synagogue or nonprofit in memory of a loved one or in
              honor of a simcha. This is the ideal way to give a donation that keeps generating additional donation revenue for the synagogue or Jewish nonprofit!
            </p>
            <Link to="/Contact">
              <button>Contact us</button>
            </Link>
          </div>
          <div>
            <img className="image_small" src={Onyx} alt="Onyx device" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
