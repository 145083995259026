import React, { useState } from "react"
import { NavLink, Link } from "react-router-dom"
import { Pages } from "./index"
import { Logo, HamburgerCloser, HamburgerOpener } from "../assets/images"
import "../styles/Navbar.scss"
import ClickOutside from "./clickOutside"

function Navbar() {
  const menuItems = [
    { path: Pages.HOME.path },
    { path: Pages.SOLUTIONS.path, name: "Solutions" },
    { path: Pages.FEATURES.path, name: "Features" },
    { path: Pages.PRODUCTS.path, name: "Products" },
   
    { path: Pages.CONTACT.path, name: "Contact" },
  ]

  const [toggleMenu, setToggleMenu] = useState(false)

  const itemClick = () => {
    setToggleMenu(false)
  }

  let menuClose = ClickOutside(() => {
    setToggleMenu(false)
  });
  

  return (
    <div className="navbar">
      <div className="wrapper_navbar">
        <div className="left_side">
          <Link to="/">
            <img src={Logo} alt="Logo Tap4Give" />
          </Link>
        </div>
        <div className="right_side">
          {menuItems.map((item, index) => (
            <div key={index}>
              <NavLink to={item.path || ""}>
                <span>{item.name}</span>
              </NavLink>
            </div>
          ))}
        </div>

        <div ref={menuClose} className="menu">
          <img src={toggleMenu ? HamburgerCloser : HamburgerOpener} alt="Toggle" onClick={() => setToggleMenu(!toggleMenu)} />
          {toggleMenu && (
            <ul className="menu_list">
              {menuItems.map((item, index) => (
                <div key={index}>
                  <NavLink
                    to={item.path || ""}
                    onClick={(e) => {
                      if (!item.path) e.preventDefault()
                      itemClick()
                    }}
                  >
                    <li>{item.name}</li>
                  </NavLink>
                </div>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar
