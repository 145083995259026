import React from "react"
import "../styles/FAQ.scss"

function FAQ() {
  return (
    <div className="faq">
      <div className="gray_background">
        <div className="wrapper">
          <h2>Frequently Asked Questions</h2>

          <div className="white_square">
            <div className="center_row">
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
            </div>

            <div className="center_row">
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
              <div className="center_column">
                <div>
                  <h3>How does each donation box work?</h3>
                  <p>Pellentesque neque in vitae turpis dapibus diam duis amet. Arcu augue blandit auctor auctor varius dui consectetur. Ac mattis dapibus volutpat, pulvinar eget sed sit nisi.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ
