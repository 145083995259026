import Alipay from "./Alipay.png"
import Apple from "./Apple.png"
import At from "./At.png"
import BckgrHome from "./bckgrHome.jpg"
import BckgrSolutions from "./bckgrSolutions.jpg"
import Contactless from "./contactless.png"
import Dash from "./Dash.png"
import Discover from "./Discover.png"
import Dots from "./dots.png"
import Engin from "./engineering.png"
import Felica from "./Felica.png"
import Googlepay from "./Googlepay.png"
import HamburgerCloser from "./Hamburger_close.svg"
import HamburgerOpener from "./Hamburger_open.svg"
import Holders from "./holders.png"
import Interac from "./Interac.png"
import Logo from "./Logo.svg"
import LogoBlue from "./LogoBlue.png"
import LogoInvert from "./LogoInvert.png"
import Maestro from "./Maestro.png"
import Mastercard from "./Mastercard.png"
import Mobilepay from "./Mobilepay.png"
import Monyx from "./Monyx.png"
import Nayax from "./Nayax.png"
import Onyx from "./Onyx.png"
import OnyxFlat from "./OnyxFlat.png"
import PayPal from "./PayPal.png"
import Phone from "./Phone.png"
import Swish from "./Swish.png"
import TouchScreen from "./touch-screen.png"
import Twint from "./Twint.png"
import Visa from "./Visa.png"
import VPay from "./VPay.png"
import Vpos from "./Vpos.png"
import VposTouch from "./vposTouch.png"
import Wechat from "./Wechat.png"

export {Alipay, Apple, At, BckgrHome, BckgrSolutions, Contactless, Dash, Discover, Dots, Engin, Felica, Googlepay, HamburgerCloser, HamburgerOpener, Holders, Interac, Logo, LogoBlue, LogoInvert, Maestro, Mastercard, Mobilepay, Monyx, Nayax, Onyx, OnyxFlat, PayPal, Phone, Swish, TouchScreen, Twint, Visa, VPay, Vpos, VposTouch, Wechat}
