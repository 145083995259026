import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Pages } from "./pages/index"
import Navbar from "./pages/Navbar"
import Footer from "./pages/Footer"
import "./styles/App.scss"
import ScrollToTop from "./pages/scrollToTop"

const pagesArray = Object.values(Pages)

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          {pagesArray.map((page) => (
            <Route exact path={page.path} key={page.path}>
              {page.component}
            </Route>
          ))}
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

export default App
