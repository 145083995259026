import React from "react"
import "../styles/Product.scss"
import { Holders } from "../assets/images"

function Products() {
  return (
    <div className="product">
      <div className="hero_product">
        <div className="wrapper_product">
          <div className="header">
            <h1> Contactless Tzedakah Box</h1>
            <p>Contactless donations for the Jewish Community</p>
          </div>
        </div>
      </div>
      <div className="block_center">
        <img src={Holders} alt="Device on a holder" />
      </div>
    </div>
  )
}

export default Products
