import React from "react"
import { Link } from "react-router-dom"
import ReactPlayer from "react-player"
import { Dots, Nayax } from "../assets/images"
import "../styles/Features.scss"

function Features() {
  const topFunction = () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  return (
    <div className="features">
      <div className="hero_features">
        <div className="wrapper_features">
          <div className="header">
            <h1>Why Tap2Give?</h1>
            <p>
              Tap2Give powered by Nayax <br />
              offers many flexible options with more to come.
            </p>
          </div>
          <div className="devices">
            <img className="dots" src={Dots} alt="Dots" />
            <img className="image_big" src={Nayax} alt="Nayax devices" />
          </div>
        </div>
      </div>
      <div className="block_center">
        <h2>Basic Features </h2>
        <div className="block_benefits">
          <div className="each_benefit">
            <h1>01</h1>
            <h3>Up to 6 different donation amounts startinng at $1 and up to $500. You decide the amounts without being limited to the cash in your wallet.</h3>
          </div>
          <div className="each_benefit">
            <h1>02</h1>
            <h3>All forms of secure EMV chip insert and tap credit card payments to phone payments with Apple, Google and Samsung Pay. All processed within a few seconds. </h3>
          </div>
        </div>
        <div className="block_benefits">
          <div className="each_benefit">
            <h1>03</h1>
            <h3>
              Allows receiving electronic receipts for tax purposes, with:
              <ul>
                <li>Up to 6 donation amounts starting at $1 and up to $500;</li>
                <li>All forms of secure EMV chip insert and tap credit card payments to phone payments with Apple, Google and Samsung Pay;</li>
                <li>An instant receipt immediately sent electronically to customers email address. Synagogue and Jewish nonprofit have access to the donors transaction amount, date, name and email address.</li>
              </ul>
            </h3>
          </div>
          <div className="each_benefit">
            <h1>04</h1>
            <h3>Up to 6 different donation amounts startinng at $1 and up to $500. You decide the amounts without being limited to the cash in your wallet.</h3>
          </div>
        </div>
      </div>
      <div className="gray_background">
        <div className="block_video">
          <div className="player_wrapper">
            <ReactPlayer playing url="video.mp4" width="100%" height="100%" muted={true} loop={true} />
            <button onClick={topFunction} id="myBtn">
              Go up
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
