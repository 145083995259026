import Home from "./Home"
import Features from "./Features"
import Solutions from "./Solutions"
import Products from "./Products"
import FAQ from "./FAQ"
import Contact from "./Contact"


export const Pages = {
  HOME: { component: <Home />, path: `/` },
  SOLUTIONS: { component: <Solutions />, path: `/solutions`, name: "Solutions" },
  FEATURES: { component: <Features />, path: `/features`, name: "Features" },
  PRODUCTS: { component: <Products />, path: `/products`, name: "Products" },
  FAQ: { component: <FAQ />, path: `/faq`, name: "FAQ" },
  CONTACT: { component: <Contact />, path: `/contact`, name: "Contact" },
}
