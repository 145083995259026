import React from "react"
import { Link } from "react-router-dom"
import { LogoInvert, Phone, At } from "../assets/images"
import "../styles/Footer.scss"
import { Pages } from "./index"

const menuItems = [
  { path: Pages.SOLUTIONS.path, name: "Solutions" },
  { path: Pages.FEATURES.path, name: "Features" },
  { path: Pages.PRODUCTS.path, name: "Products" },
  { path: Pages.FAQ.path, name: "FAQ" },
]

function Footer() {
  return (
    <div className="footer">
      <div className="wrapper_footer">
        <div className="rows">
          <div className="logo_footer">
            <Link to="/">
              <img src={LogoInvert} alt="Logo Tap4Give" />
            </Link>
            <p>Company that specialized in custom made donation solutions for charity organizations.</p>
          </div>
          <div className="column_footer">
            <p className="headers">Contact</p>
            <div className="row_footer">
              <img src={Phone} alt="Phone" />
              <p>+1-647-283-0504</p>
            </div>
            <div className="row_footer">
              <img src={At} alt="At" />
              <p>contactus@tap2give.info</p>
            </div>
          </div>
          <div className="column_footer">
            <p className="headers headers_link">Links</p>
            {menuItems.map((item, index) => (
              <div key={index}>
                <Link to={item.path || ""}>
                  <span className="list">{item.name}</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="copyright_footer">
          <p>Copyright 2021-2022 eLogic Inc. Designed by Potapova T.</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
