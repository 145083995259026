import React from "react"
import { Link } from "react-router-dom"
import "../styles/Solutions.scss"
import { Dash, Vpos, Onyx, Contactless, Engin, TouchScreen, Mastercard, VPay, Maestro, Visa, Interac, Discover, Apple, Googlepay, PayPal, Monyx, Wechat, Twint, Felica, Alipay, Swish, Mobilepay } from "../assets/images"

function Solutions() {
  const first = [
    { name: "mastercard", icon: Mastercard },
    { name: "vpay", icon: VPay },
    { name: "maestro", icon: Maestro },
  ]
  const second = [
    { name: "mastercard", icon: Visa },
    { name: "vpay", icon: Interac },
    { name: "maestro", icon: Discover },
  ]
  const third = [
    { name: "mastercard", icon: Apple },
    { name: "vpay", icon: Googlepay },
    { name: "maestro", icon: PayPal },
    { name: "maestro", icon: Monyx },
  ]
  const fourth = [
    { name: "mastercard", icon: Wechat },
    { name: "vpay", icon: Twint },
    { name: "maestro", icon: Felica },
  ]
  const fifth = [
    { name: "mastercard", icon: Alipay },
    { name: "vpay", icon: Swish },
    { name: "maestro", icon: Mobilepay },
  ]

  return (
    <div className="solutions">
      <div className="hero">
        <div className="wrapper_home">
          <div className="header">
            <h2>Cashless Payment Solutions</h2>
            <h3>Leading the way in creating a cashless society</h3>
            <p>These days fewer people are using cash, with Generation Z even prefering to use digital solutions to pay for services and products. Cashless payments aren't just the future, they are already the present.</p>
          </div>
        </div>
      </div>
      <div className="block_center">
        <h2>Our Donation Systems</h2>
        <img src={Dash} alt="Dash" />
        <p>
          Choose from our donation systems to increase ease of operational tasks and sales.
          <br />
          Our company provides customizable solutions for donations collected via cashless methods.
        </p>
      </div>
      <div className="gray_background">
        <div className="block">
          <div>
            <img className="image_small" src={Vpos} alt="Vpos" />
          </div>
          <div className="block_text">
            <h1>VPOS Touch</h1>
            <h4>An all-in-one device</h4>
            <img src={Dash} alt="Dash" />
            <p>The VPOS Touch can enable operators to communicate with their consumers more directly and nurture loyalty by taking advantage of Management Suite's built-in marketing tools. </p>
            <p>Run campaigns like digital punch cards, discounts, sales and happy hours, providing your consumer a richer sales experience. Use the color touchscreen's idle mode to advertise the many campaigns you can promote.</p>
            <Link to="/Contact">
              <button>Contact us</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="block_text">
          <h1>Onyx</h1>
          <h4>Contactless card reader and telemetry device</h4>
          <img src={Dash} alt="Dash" />
          <p>
            The sleek, contactless card reader can fit on any small machine and gives your consumer a convenient cashless payment experience. While consumers complete transactions quickly, operators access a world of operational benefits
            and insights.
          </p>
          <p>The Onyx is more than a card reader: It's a device packed with powerful capabilities including contactless, NFC, and QR payments, telemetry, marketing tools, and remote machine management.</p>
          <Link to="/Contact">
            <button>Contact us</button>
          </Link>
        </div>
        <div>
          <img className="image_small" src={Onyx} alt="Onyx" />
        </div>
      </div>
      <div className="gray_background">
        <div className="block_solutions">
          <h2>Benefits</h2>
          <img src={Dash} alt="Dash" />
          <p>
            Nayax cards offers you a complete solution, ensuring you can monitor your business and increase donations the organization collecting.
            <br />
            Custom design allows the unit to fit any place or event.
          </p>
          <div className="benefits">
            <div className="each_benefit">
              <img src={Contactless} alt="Contactless" />
              <h3>Contactless payments</h3>
            </div>
            <div className="each_benefit">
              <img src={TouchScreen} alt="Touch screen" />
              <h3>Intuitive user interface</h3>
            </div>
            <div className="each_benefit">
              <img src={Engin} alt="Gear" />
              <h3>System remote managment</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="block_center">
        <h2>Multiple payment methods</h2>
        <img src={Dash} alt="Dash" />
        <p>
          Nayax car readers enables you to accept a wide array of global payment providers and popular local payment
          <br />
          services, offering your consumers all the payment methods they are familiar with.
        </p>
        <div className="block">
          <div className="cards">
            <h3>Debit and Credit cards</h3>
            <div className="cards_group">
              <div className="cards_row">
                {first.map((icon, key) => (
                  <div className="cards_row" key={key}>
                    <img src={icon.icon} alt={`${icon.name}`} />
                  </div>
                ))}
              </div>
              <div className="cards_row">
                {second.map((icon, key) => (
                  <div className="cards_row" key={key}>
                    <img src={icon.icon} alt={`${icon.name}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="cards">
            <h3>Mobile and NFC</h3>
            <div className="cards_group">
              <div className="cards_row">
                {third.map((icon, key) => (
                  <div className="cards_row" key={key}>
                    <img src={icon.icon} alt={`${icon.name}`} />
                  </div>
                ))}
              </div>
              <div className="cards_row">
                {fourth.map((icon, key) => (
                  <div className="cards_row" key={key}>
                    <img src={icon.icon} alt={`${icon.name}`} />
                  </div>
                ))}
              </div>
              <div className="cards_row">
                {fifth.map((icon, key) => (
                  <div className="cards_row" key={key}>
                    <img src={icon.icon} alt={`${icon.name}`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Solutions
